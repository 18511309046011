
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

import "./CarroselClientes.css"


const items = [
  {
    src: '/Imagens/LOGO_GAUSS.png',
    link: 'https://gauss.com.br/',
    src2: '/Imagens/LOGO_CDG.png',
    link2: 'https://gauss.com.br/',
    src3: '/Imagens/LOGO_SPG.png',
    link3: 'https://gauss.com.br/',

    altText: '1',
    caption: 'Gauss Indústria Auto-Peças',
    key: 1,
  },
  {
    src: '/Imagens/LOGO_NSG.png',
    link: 'https://gauss.com.br/',
    src2: '/Imagens/lc1.png',
    link2: 'https://lc1contadores.com.br',
    src3: '/Imagens/brpavin.png',
    link3: 'https://brpaving.com.br/',

    altText: '2',
    caption: 'CDG Logística',
    key: 2,
  },
 
];

function CarrosselClientes(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <a href={item.link}><img id="imagem-carrosel-clientes" src={item.src} alt={item.altText} /></a>
        <a href={item.link2}><img id="imagem-carrosel-clientes" src={item.src2} alt={item.altText} /></a>
        <a href={item.link3}><img id="imagem-carrosel-clientes" src={item.src3} alt={item.altText} /></a>

       
      </CarouselItem>

    );
  }




  );

  return (
  
  <div className='container-carrossel-cliente'>
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >

      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  </div>);
}

export default CarrosselClientes;